import React from "react"
import { graphql } from "gatsby"
import { Base64 } from "js-base64"

import ToolLayout from "../../../components/tool_layout"
import PipeWidget from "../../../components/widgets/pipe"

export const query = graphql`
  query Base64DecodeQuery {
    allToolsJson(filter: { path: { eq: "base64/decode" } }) {
      edges {
        node {
          path
          title
          description
          links {
            mdn
            github
          }
        }
      }
    }
  }
`

class Base64DecodePage extends React.Component<{ data: any }> {
  render() {
    const transformer = Base64.decode

    return (
      <ToolLayout data={this.props.data}>
        <PipeWidget
          transformer={transformer}
          exceptionHandler={e => {
            if (e) {
              return "Invalid input"
            }
          }}
          inputProps={{
            placeHolderText: "Input text here",
          }}
          outputProps={{
            placeHolderText: "Output will be shown here",
          }}
        />
      </ToolLayout>
    )
  }
}

export default Base64DecodePage
